import { store } from "../../App";
import { DEFAULT_LANG } from "../../constants";

export const GET_BEVERAGES = "beverages/GET_BEVERAGES";
export const GET_BEVERAGES_SUCCESS = "beverages/GET_BEVERAGES_SUCCESS";
export const GET_BEVERAGES_ERROR = "beverages/GET_BEVERAGES_ERROR";
export const GET_BEVERAGES_TRANSLATIONS = "beverages/GET_BEVERAGES_TRANSLATIONS";
export const GET_BEVERAGES_TRANSLATIONS_SUCCESS = "beverages/GET_BEVERAGES_TRANSLATIONS_SUCCESS";
export const GET_BEVERAGES_TRANSLATIONS_ERROR = "beverages/GET_BEVERAGES_TRANSLATIONS_ERROR";

export const GET_BEVERAGE_DETAIL = "beverages/GET_BEVERAGE_DETAIL";
export const GET_BEVERAGE_DETAIL_SUCCESS = "beverages/GET_BEVERAGE_DETAIL_SUCCESS";
export const GET_BEVERAGE_DETAIL_ERROR = "beverages/GET_BEVERAGE_DETAIL_ERROR";
export const CREATE_AND_APPROVE_BEVERAGE = "beverages/CREATE_AND_APPROVE_BEVERAGE";
export const EDIT_BEVERAGE = "beverages/EDIT_BEVERAGE";
export const SET_CURRENT_BEVERAGE = "beverages/SET_CURRENT_BEVERAGE";
export const MERGE_BEVERAGE = "beverages/MERGE_BEVERAGE";

export interface IBeverage {
  id: string;
  brand: string;
  subbrand: string;
  active: boolean;
  countries: ICountry[];
  type?: string;
  description?: string;
  abv?: number;
  countryOfOrigin: string;
  carlsShopUrl?: string;
  mainColor?: string;
  initials?: string;
}

export interface ILocale {
  brand: string;
  subbrand: string;
  type: string;
  description: string;
  countryOfOrigin: string;
  logoUrl: string;
  videoUrl?: string;
  documentUrl?: string;
  logoFile?: File | string;
  videoFile?: File | string;
  documentFile?: File | string;
}

export interface ICountry {
  id: string;
  code: string;
  name: string;
}

export interface IBeverageCreatePayload {
  id?: string;
  availability: boolean;
  active: boolean;
  abv?: number;
  translations: { [key: string]: ILocale };
}

const getPropertyOfBeverage = (beverage: BeverageTranslation, key: keyof ILocale, locale = "") => {
  const lang = store.getState().content.currentLanguage;

  // use default lang as fallback
  return locale
    ? beverage.locales[locale] && beverage.locales[locale][key]
    : (beverage.locales[lang] && beverage.locales[lang][key]) ||
        (beverage.locales[process.env.REACT_APP_DEFAULT_LANG] &&
          beverage.locales[process.env.REACT_APP_DEFAULT_LANG][key]);
};

export interface IBeverageTranslation {
  id: string;
  countries: ICountry[];
  active: boolean;
  abv?: number;
  carlsShopUrl?: string;
  mainColor?: string;
  initials?: string;
  locales: { [key: string]: ILocale };
}

const LOCALES_KEYS = ["description", "type", "countryOfOrigin"];

export class BeverageTranslation implements IBeverageTranslation {
  id = "";

  countries: ICountry[] = [];

  active = false;

  abv?: number;

  carlsShopUrl?: string;

  mainColor?: string;

  initials?: string;

  locales: { [key: string]: ILocale } = {
    [process.env.REACT_APP_DEFAULT_LANG]: {
      brand: "",
      subbrand: "",
      description: "",
      countryOfOrigin: "",
      type: "",
      logoUrl: "",
      videoUrl: "",
      documentUrl: ""
    }
  };

  constructor(beverage?: any, translations?: any) {
    if (beverage) {
      this.id = beverage.id;
      this.countries = beverage.countries;
      this.active = beverage.active;
      this.abv = beverage.abv;
      this.carlsShopUrl = beverage.carlsShopUrl;
      this.mainColor = beverage.mainColor;
      this.initials = beverage.initials;
      this.locales = beverage.locales || {};
      if (!beverage.locales) {
        this.locales[DEFAULT_LANG] = {
          brand: beverage.brand,
          subbrand: beverage.subbrand,
          countryOfOrigin: beverage.countryOfOrigin,
          description: beverage.description,
          type: beverage.type,
          logoUrl: beverage.logoUrl
        };
      }

      if (translations) {
        translations?.forEach((t: any) => {
          this.locales[t.locale] = {
            brand: t.brand,
            subbrand: t.subbrand,
            countryOfOrigin: t.countryOfOrigin,
            description: t.description,
            type: t.type,
            logoUrl: t.logoUrl
          };
        });
      }
    }
  }

  get brand() {
    return getPropertyOfBeverage(this, "brand");
  }

  get subbrand() {
    return getPropertyOfBeverage(this, "subbrand");
  }

  get description() {
    return getPropertyOfBeverage(this, "description");
  }

  get type() {
    return getPropertyOfBeverage(this, "type");
  }

  get countryOfOrigin() {
    return getPropertyOfBeverage(this, "countryOfOrigin");
  }

  get name() {
    return `${this.brand} ${this.subbrand}`;
  }

  logoUrl(locale = "") {
    const imageRequest = JSON.stringify({
      bucket: process.env.REACT_APP_DEFAULT_LOGO_BUCKET,
      key: locale ? this.logoKey(locale) : this.logoKey()
      // edits: {
      //   resize: {
      //     width: 180,
      //       height: 180
      //   }
      // }
    });

    // use default lang as fallback
    return (locale && this.logoKey(locale)) || (!locale && this.logoKey())
      ? `${process.env.REACT_APP_DEFAULT_CLOUDFRONT_URL}/${btoa(imageRequest)}`
      : null;
  }

  logoKey(locale = "") {
    return getPropertyOfBeverage(this, "logoUrl", locale);
  }

  logoFile(locale = "") {
    return getPropertyOfBeverage(this, "logoFile", locale);
  }

  videoUrl(locale = "") {
    const key = (locale && this.videoKey(locale)) || (!locale && this.videoKey());

    // use default lang as fallback
    return key ? `${process.env.REACT_APP_DEFAULT_VIDEO_URL}/${key}` : null;
  }

  videoKey(locale = "") {
    return getPropertyOfBeverage(this, "videoUrl", locale);
  }

  videoFile(locale = "") {
    return getPropertyOfBeverage(this, "videoFile", locale);
  }

  documentUrl(locale = "") {
    const key = (locale && this.documentKey(locale)) || (!locale && this.documentKey());

    // use default lang as fallback
    return key ? `${process.env.REACT_APP_DEFAULT_DOCUMENT_URL}/${key}` : null;
  }

  documentKey(locale = "") {
    return getPropertyOfBeverage(this, "documentUrl", locale);
  }

  documentFile(locale = "") {
    return getPropertyOfBeverage(this, "documentFile", locale);
  }

  localePercentage(locale = process.env.REACT_APP_DEFAULT_LANG) {
    const currentLocale: any = this.locales[locale];

    if (currentLocale) {
      let filled = 0;
      const total = LOCALES_KEYS.length;

      Object.keys(currentLocale).forEach((k: string) => {
        if (LOCALES_KEYS.includes(k) && currentLocale[k] && currentLocale[k] !== "") {
          filled++;
        }
      });

      return Math.round((filled / total) * 100);
    }

    return 0;
  }

  readyToSubmit() {
    return (
      this.locales[process.env.REACT_APP_DEFAULT_LANG] &&
      this.locales[process.env.REACT_APP_DEFAULT_LANG].brand &&
      this.locales[process.env.REACT_APP_DEFAULT_LANG].subbrand
    );
  }
}

export interface IBeveragesState {
  beverages: BeverageTranslation[];
  beveragesX10: BeverageTranslation[];
  translations: BeverageTranslation[];
  requestingBeverages: boolean;
  requestingBeveragesError: boolean;
  requestingTranslations: boolean;
  requestingTranslationsError: boolean;
  requestingBeverageDetail: boolean;
  requestingBeverageDetailError: boolean;
  currentBeverage?: BeverageTranslation;
}

export interface IGetBeverages {
  type: typeof GET_BEVERAGES;
}

export interface IGetBeveragesSuccess {
  type: typeof GET_BEVERAGES_SUCCESS;
  payload: { beverages: BeverageTranslation[]; beveragesX10: BeverageTranslation[] };
}

export interface IGetBeveragesError {
  type: typeof GET_BEVERAGES_ERROR;
}

export interface IGetBeveragesTranslations {
  type: typeof GET_BEVERAGES_TRANSLATIONS;
}

export interface IGetBeveragesTranslationsSuccess {
  type: typeof GET_BEVERAGES_TRANSLATIONS_SUCCESS;
  payload: BeverageTranslation[];
}

export interface IGetBeveragesTranslationsError {
  type: typeof GET_BEVERAGES_TRANSLATIONS_ERROR;
}

export interface IGetBeverageDetail {
  type: typeof GET_BEVERAGE_DETAIL;
  payload: string;
}

export interface IGetBeverageDetailSuccess {
  type: typeof GET_BEVERAGE_DETAIL_SUCCESS;
  payload: BeverageTranslation;
}

export interface IGetBeverageDetailError {
  type: typeof GET_BEVERAGE_DETAIL_ERROR;
}

export interface ISetCurrentBeverage {
  type: typeof SET_CURRENT_BEVERAGE;
  payload: BeverageTranslation;
}

export interface ICreateAndApproveBeverage {
  type: typeof CREATE_AND_APPROVE_BEVERAGE;
  payload: IBeverageTranslation;
}

export interface IEditBeverage {
  type: typeof EDIT_BEVERAGE;
  payload: IBeverageTranslation;
}

export interface IMergeBeverageProps {
  beverageToMergeId: string;
  officialBeverageId: string;
}

export interface IMergeBeverage {
  type: typeof MERGE_BEVERAGE;
  payload: IMergeBeverageProps;
}

export type BeveragesActionTypes =
  | IGetBeverages
  | IGetBeveragesSuccess
  | IGetBeveragesError
  | IGetBeveragesTranslations
  | IGetBeveragesTranslationsSuccess
  | IGetBeveragesTranslationsError
  | IGetBeverageDetail
  | IGetBeverageDetailSuccess
  | IGetBeverageDetailError
  | ISetCurrentBeverage
  | ICreateAndApproveBeverage
  | IEditBeverage
  | IMergeBeverage;
