import classnames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { lightOrDark } from "../../../helpers";
import Icon from "../icon/Icon";
import "./logoUpload.scss";
import useContent from "../../../hooks/useContent";

export interface IProps {
  name?: string;
  label?: string;
  mainColor?: string;
  initials?: string;
  value: any;
  defaultValue?: any;
  onChange: (file: File | null) => void;
  acceptPdf?: boolean;
}

export const LogoUpload: React.FC<IProps> = ({
  name = "",
  label,
  mainColor,
  initials,
  value,
  defaultValue,
  onChange,
  acceptPdf
}) => {
  const { controlTowerBeveragesPage: content } = useContent();

  const [fileInput, setFileInput] = useState<HTMLInputElement | null>(null);
  const [image, setImage] = useState<any>(null);
  const [isPdf, setIsPdf] = useState<boolean>(false);

  useEffect(() => {
    async function checkFileType(url: string) {
      try {
        const response = await fetch(url, { method: "HEAD" });
        const contentType = response.headers.get("Content-Type");

        if (contentType?.includes("application/pdf")) {
          setIsPdf(true);
        }
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.result) {
        setImage(reader.result);
        setIsPdf((reader.result as string).includes("application/pdf"));
      }
    };

    if (value && value instanceof File) {
      reader.readAsDataURL(value);
    } else if (!value && defaultValue && defaultValue instanceof File) {
      reader.readAsDataURL(defaultValue);
    } else if (value) {
      checkFileType(value);
      setImage(null);
    }
  }, [value, defaultValue]);

  const downloadLogo = useCallback(() => {
    if (image) {
      const a = document.createElement("a"); // create html element anchor

      a.href = image; // xhr.response is a blob
      a.download = `${name}_${label}.${isPdf ? "pdf" : "png"}`; // Set the file name.
      a.style.display = "none"; // set anchor as hidden
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      const xhr = new XMLHttpRequest();

      xhr.responseType = "blob";
      xhr.onload = () => {
        const a = document.createElement("a"); // create html element anchor

        a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob

        a.download = `${name}_${label}.${isPdf ? "pdf" : "png"}`; // Set the file name.
        a.style.display = "none"; // set anchor as hidden
        document.body.appendChild(a);
        a.click();
        a.remove();
      };
      xhr.open("GET", value);
      xhr.send();
    }
  }, [isPdf, image, label, name, value]);

  const deleteLogo = useCallback(() => {
    setImage(null);
    setIsPdf(false);
    onChange(null);
  }, [onChange]);

  const isDarkColor = useMemo(() => lightOrDark(mainColor) === "dark", [mainColor]);

  return (
    <div className="logo-upload">
      {label && <span className="label">{label}</span>}
      {!value || !isPdf ? (
        <div
          className="logo-background"
          style={{
            backgroundImage: `url(${image || value || defaultValue})`,
            backgroundColor:
              !value && !defaultValue && mainColor
                ? mainColor
                : !image && !value
                ? "var(--color-grey-grey50)"
                : ""
          }}
        >
          <div
            className={classnames("logo-overlay", {
              "logo-default": !value && defaultValue
            })}
            onClick={() => fileInput && fileInput.click()}
          >
            <Icon name={acceptPdf ? "add" : "camera"} size={40} />
            {!value && defaultValue && (
              <span style={{ maxWidth: "80%" }}>{content.control_tower_using_english_field}</span>
            )}
            {initials && !value && !defaultValue && (
              <div
                className="initials"
                style={{ color: isDarkColor ? "white" : "var(--color-text)" }}
              >
                {initials}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className="logo-background flex items-center justify-center"
          style={{
            backgroundColor: "var(--color-white)"
          }}
        >
          <span className="w-full">{value?.name || content.control_tower_asset_file}</span>
          <div
            className={classnames("logo-overlay")}
            onClick={() => fileInput && fileInput.click()}
          >
            <Icon name={acceptPdf ? "add" : "camera"} size={40} />
            {!value && defaultValue && (
              <span style={{ maxWidth: "80%" }}>{content.control_tower_using_english_field}</span>
            )}
            {initials && !value && !defaultValue && (
              <div
                className="initials"
                style={{ color: isDarkColor ? "white" : "var(--color-text)" }}
              >
                {initials}
              </div>
            )}
          </div>
        </div>
      )}
      {value && (
        <div style={{ marginBottom: "var(--space-unit-md)" }}>
          <span className="logo-button logo-download" onClick={downloadLogo}>
            {content.control_tower_download}
          </span>
          <span className="logo-button logo-delete" onClick={deleteLogo}>
            {content.control_tower_delete}
          </span>
        </div>
      )}

      <input
        ref={ref => setFileInput(ref)}
        type="file"
        style={{ display: "none" }}
        name="logo"
        onChange={e => {
          if (e.target && e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const filesize = (file.size / 1024 / 1024).toFixed(4);

            const filerdr = new FileReader();

            // eslint-disable-next-line
            filerdr.onload = function (fileE) {
              const elem = document.createElement("img");

              elem.onerror = e => {
                console.log(e);
                // eslint-disable-next-line no-alert
                alert(content.control_tower_wrong_type);
              };
              elem.onload = () => {
                if (parseInt(filesize, 10) <= 10) {
                  onChange(file);
                } else {
                  // eslint-disable-next-line no-alert
                  alert(content.control_tower_too_big_file);
                }
              };

              if (file.type !== "application/pdf") {
                elem.src = fileE.target?.result as string;
                elem.remove();
                if (parseInt(filesize, 10) <= 10) {
                  onChange(file);
                } else {
                  // eslint-disable-next-line no-alert
                  alert(content.control_tower_too_big_file);
                }
              } else {
                setIsPdf(true);
                if (parseInt(filesize, 10) <= 10) {
                  onChange(file);
                } else {
                  // eslint-disable-next-line no-alert
                  alert(content.control_tower_too_big_file);
                }
              }
            };

            filerdr.readAsDataURL(file);
          }
        }}
        accept={`${acceptPdf ? "application/pdf, " : ""}image/png, image/jpeg`}
      />
    </div>
  );
};

export default LogoUpload;
