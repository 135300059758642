import { call, put, select, takeLatest } from "redux-saga/effects";
import { ddmTheme } from "@ddm-design-system/tokens";
import { store } from "../App";
import history from "../history";
import { IAppContext } from "../services";
import { IJCoreService } from "../services/jcore";
import {
  getBeverageDetail as getBeverageDetailAction,
  getBeverageDetailError,
  getBeverageDetailSuccess,
  getBeveragesSuccess,
  getBeveragesTranslationsSuccess
} from "../store/beverages/actions";
import {
  BeverageTranslation,
  CREATE_AND_APPROVE_BEVERAGE,
  EDIT_BEVERAGE,
  GET_BEVERAGE_DETAIL,
  GET_BEVERAGES,
  GET_BEVERAGES_TRANSLATIONS,
  IBeverage,
  IBeverageTranslation,
  ICreateAndApproveBeverage,
  IGetBeverageDetail,
  IMergeBeverage,
  MERGE_BEVERAGE
} from "../store/beverages/types";
import { getCurrentContent } from "../store/content/selectors";
import { sleep } from "../helpers";

export function* getBeverages(jCoreService: IJCoreService) {
  try {
    const response = yield call([jCoreService, jCoreService.fetchBeverages]);
    const translatedBeverages: BeverageTranslation[] = response
      .filter((b: any) => !(b.brand === "empty" || b.name === "No Beverage"))
      .map((d: IBeverage) => {
        return new BeverageTranslation({
          id: d.id,
          // remove test countries
          countries: d.countries.filter(c => c.code !== "ZZ"),
          active: d.active,
          abv: d.abv,
          carlsShopUrl: d.carlsShopUrl,
          mainColor: d.mainColor,
          initials: d.initials,
          locales: {
            [process.env.REACT_APP_DEFAULT_LANG]: {
              brand: d.brand,
              subbrand: d.subbrand,
              type: d.type,
              description: d.description,
              countryOfOrigin: d.countryOfOrigin
            }
          }
        });
      });

    const translatedResponse = yield call([jCoreService, jCoreService.fetchBeveragesTranslations]);

    Object.keys(translatedResponse).forEach((key: string) => {
      const beverage = translatedBeverages.find(f => f.id === key);

      // tslint:disable-next-line:no-any
      translatedResponse[key].forEach((d: any) => {
        if (beverage) {
          beverage.locales[d.locale] = d;
        }
      });
    });

    const { beverages: beveragesX10, beveragesTranslations: beveragesX10Translations } = yield call(
      [jCoreService, jCoreService.fetchBeveragesX10]
    );

    const translatedBeveragesX10: BeverageTranslation[] = beveragesX10.map(
      (d: IBeverage) => new BeverageTranslation(d, beveragesX10Translations[d.id])
    );

    yield put(getBeveragesSuccess(translatedBeverages, translatedBeveragesX10));
  } catch {
    // console.log("Error ");
  }
}

export function* getBeveragesTranslations(jCoreService: IJCoreService) {
  try {
    const response = yield call([jCoreService, jCoreService.fetchBeveragesTranslations]);

    yield put(getBeveragesTranslationsSuccess(response));
  } catch {
    // console.log("Error ");
  }
}

export function* getBeverageDetail(jCoreService: IJCoreService, action: IGetBeverageDetail) {
  try {
    const response = yield call([jCoreService, jCoreService.fetchBeverage], action.payload);
    const translatedBeverage = new BeverageTranslation({
      id: response.id,
      countries: response.countries,
      active: response.active,
      abv: response.abv,
      carlsShopUrl: response.carlsShopUrl,
      mainColor: response.mainColor,
      initials: response.initials,
      locales: {
        [process.env.REACT_APP_DEFAULT_LANG]: {
          brand: response.brand,
          subbrand: response.subbrand,
          type: response.type,
          description: response.description,
          countryOfOrigin: response.countryOfOrigin
        }
      }
    });
    const translatedResponse = yield call(
      [jCoreService, jCoreService.fetchBeverageTranslations],
      action.payload
    );

    // tslint:disable-next-line:no-any
    translatedResponse.forEach((d: any) => {
      if (translatedBeverage) {
        translatedBeverage.locales[d.locale] = d;
      }
    });

    const beverageTranslation: BeverageTranslation = new BeverageTranslation(translatedBeverage);

    yield put(getBeverageDetailSuccess(beverageTranslation));
  } catch {
    yield put(getBeverageDetailError());
    // console.log("Error ");
  }
}

const prepareBeverageRequest = (beverage: IBeverageTranslation) => {
  const { me } = store.getState().authReducer;

  return {
    id: beverage.id,
    availability: beverage.countries.map(c => c.code).includes(me ? me.country.code : ""),
    active: true,
    abv: beverage.abv,
    carlsShopUrl: beverage.carlsShopUrl,
    mainColor: beverage.mainColor,
    initials: beverage.initials,
    translations: beverage.locales
  };
};

export function* createAndApproveBeverage(
  { jCoreService, notificationService }: IAppContext,
  action: ICreateAndApproveBeverage
) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};

  try {
    const response = yield call(
      [jCoreService, jCoreService.createAndApproveBeverage],
      prepareBeverageRequest(action.payload)
    );

    // brand logos
    const files: any[] = [];

    Object.keys(action.payload.locales).forEach(locale => {
      if (action.payload.locales[locale].logoFile) {
        files.push({ file: action.payload.locales[locale].logoFile, locale });
      }
    });

    yield call([jCoreService, jCoreService.uploadLogoBeverage], response.id, files);

    // digital lens video
    const filesVideo: any[] = [];

    Object.keys(action.payload.locales).forEach(locale => {
      if (action.payload.locales[locale].videoFile) {
        filesVideo.push({ file: action.payload.locales[locale].videoFile, locale });
      }
    });

    yield call([jCoreService, jCoreService.uploadVideoBeverage], response.id, filesVideo);

    // marketing documents
    const filesDocuments: any[] = [];

    Object.keys(action.payload.locales).forEach(locale => {
      if (action.payload.locales[locale].documentFile) {
        filesDocuments.push({ file: action.payload.locales[locale].documentFile, locale });
      }
    });

    yield call([jCoreService, jCoreService.uploadDocumentBeverage], response.id, filesDocuments);

    notificationService.addNotification({
      iconProps: { name: "ok" },
      text: (content.beverage_create || "")
        .replace("%BRAND%", response.brand)
        .replace("%SUBBRAND%", response.subbrand)
    });
    yield sleep(1000);
    yield call(getBeverages, jCoreService);
    history.goBack();
  } catch (e) {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.beverage_create_error
    });
  }
}

export function* mergeBeverage(
  { jCoreService, notificationService }: IAppContext,
  action: IMergeBeverage
) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};

  try {
    yield call([jCoreService, jCoreService.mergeBeverage], action.payload);

    notificationService.addNotification({
      iconProps: { name: "ok" },
      text: content.beverage_merge
    });
    yield call(getBeverages, jCoreService);
  } catch {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.beverage_merge_error
    });
  }
}

export function* editBeverage(
  { jCoreService, notificationService }: IAppContext,
  action: ICreateAndApproveBeverage
) {
  const { notifications: content = {} } = (yield select(getCurrentContent)) || {};

  try {
    const response = yield call(
      [jCoreService, jCoreService.editBeverage],
      prepareBeverageRequest(action.payload)
    );

    // manage logos
    const files: any[] = [];
    const deleteFiles: any[] = [];

    Object.keys(action.payload.locales).forEach(locale => {
      const { logoFile } = action.payload.locales[locale];

      if (logoFile) {
        if (logoFile !== "delete") {
          files.push({ file: logoFile, locale });
        } else {
          deleteFiles.push(locale);
        }
      }
    });

    if (files.length > 0) {
      yield call([jCoreService, jCoreService.uploadLogoBeverage], action.payload.id, files);
    }

    if (deleteFiles.length > 0) {
      yield call([jCoreService, jCoreService.deleteLogoBeverage], action.payload.id, deleteFiles);
    }

    // manage dgital lens videos
    const filesVideo: any[] = [];
    const deleteFilesVideo: any[] = [];

    Object.keys(action.payload.locales).forEach(locale => {
      const { videoFile } = action.payload.locales[locale];

      if (videoFile) {
        if (videoFile !== "delete") {
          filesVideo.push({ file: videoFile, locale });
        } else {
          deleteFilesVideo.push(locale);
        }
      }
    });

    if (filesVideo.length > 0) {
      yield call([jCoreService, jCoreService.uploadVideoBeverage], action.payload.id, filesVideo);
    }

    if (deleteFilesVideo.length > 0) {
      yield call(
        [jCoreService, jCoreService.deleteVideoBeverage],
        action.payload.id,
        deleteFilesVideo
      );
    }

    // manage marketing documents
    const filesDocuments: any[] = [];
    const deleteFilesDocuments: any[] = [];

    Object.keys(action.payload.locales).forEach(locale => {
      const { documentFile } = action.payload.locales[locale];

      if (documentFile) {
        if (documentFile !== "delete") {
          filesDocuments.push({ file: documentFile, locale });
        } else {
          deleteFilesDocuments.push(locale);
        }
      }
    });

    console.log("files", files, filesDocuments);
    if (filesDocuments.length > 0) {
      yield call(
        [jCoreService, jCoreService.uploadDocumentBeverage],
        action.payload.id,
        filesDocuments
      );
    }

    if (deleteFilesDocuments.length > 0) {
      yield call(
        [jCoreService, jCoreService.deleteDocumentBeverage],
        action.payload.id,
        deleteFilesDocuments
      );
    }

    yield put(getBeverageDetailAction(action.payload.id));

    notificationService.addNotification({
      iconProps: { name: "ok" },
      text: (content.beverage_edit || "")
        .replace("%BRAND%", response.brand)
        .replace("%SUBBRAND%", response.subbrand)
    });
    yield call(getBeverages, jCoreService);
  } catch (e) {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: ddmTheme.colors.alert.alert100 },
      text: content.beverage_edit_error
    });
  }
}

export function* beveragesWatcher(context: IAppContext) {
  yield takeLatest(GET_BEVERAGES, getBeverages, context.jCoreService);
  yield takeLatest(GET_BEVERAGE_DETAIL, getBeverageDetail, context.jCoreService);
  yield takeLatest(GET_BEVERAGES_TRANSLATIONS, getBeveragesTranslations, context.jCoreService);
  yield takeLatest(CREATE_AND_APPROVE_BEVERAGE, createAndApproveBeverage, context);
  yield takeLatest(EDIT_BEVERAGE, editBeverage, context);
  yield takeLatest(MERGE_BEVERAGE, mergeBeverage, context);
}

export default beveragesWatcher;
