import classnames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Icon from "../icon/Icon";
import "./videoUpload.scss";
import useContent from "../../../hooks/useContent";

export interface IProps {
  name?: string;
  label?: string;
  value: any;
  defaultValue: any;
  onChange: (file: File | null) => void;
}

export const VideoUpload: React.FC<IProps> = ({
  name = "",
  label,
  value,
  defaultValue,
  onChange
}) => {
  const { controlTowerBeveragesPage: content } = useContent();
  const [fileInput, setFileInput] = useState<HTMLInputElement | null>(null);
  const [video, setVideo] = useState<any>(null);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.result) {
        setVideo(reader.result);
        setPlaying(true);
      }
    };

    if (value && value instanceof File) {
      reader.readAsDataURL(value);
    } else if (!value && defaultValue && defaultValue instanceof File) {
      reader.readAsDataURL(defaultValue);
    } else {
      setVideo(null);
    }
  }, [value, defaultValue]);

  const downloadLogo = useCallback(() => {
    if (video) {
      const a = document.createElement("a"); // create html element anchor

      a.href = video; // xhr.response is a blob
      a.download = `${name}_${label}.mp4`; // Set the file name.
      a.style.display = "none"; // set anchor as hidden
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      const xhr = new XMLHttpRequest();

      xhr.responseType = "blob";
      xhr.onload = () => {
        const a = document.createElement("a"); // create html element anchor

        a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
        a.download = `${name}_${label}.mp4`; // Set the file name.
        a.style.display = "none"; // set anchor as hidden
        document.body.appendChild(a);
        a.click();
        a.remove();
      };
      xhr.onerror = () => {
        window.open(value, "_blank");
      };
      xhr.open("GET", value);
      xhr.send();
    }
  }, [video, label, name, value]);

  const deleteLogo = useCallback(() => {
    setVideo(null);
    onChange(null);
  }, [onChange]);

  return (
    <div className="video-upload">
      {label && <span className="label">{label}</span>}
      <div
        className="logo-background"
        style={{
          backgroundColor: !video && !value ? "var(--color-grey-greenish)" : ""
        }}
      >
        <div className="video-wrapper" onClick={() => value && setPlaying(!playing)}>
          {value && <ReactPlayer playing={playing} loop url={video || value || defaultValue} />}

          <div
            className={classnames("logo-overlay", {
              "logo-default": !value && defaultValue
            })}
            onClick={() => {
              if (!value) {
                fileInput?.click();
              }
            }}
          >
            {(video || value) && !playing && <Icon name="play" size={50} className="play-icon" />}

            {!value && (
              <Icon
                name="plus"
                size={50}
                className={classnames("plus-stroke", { "no-video": !video && !value })}
              />
            )}
            {!value && defaultValue && (
              <span style={{ maxWidth: "80%" }}>{content.control_tower_using_english_field}</span>
            )}
          </div>
        </div>
      </div>
      {value && (
        <div style={{ marginBottom: "var(--space-unit-md)" }}>
          <span className="logo-button logo-download" onClick={downloadLogo}>
            {content.control_tower_download}
          </span>
          <span className="logo-button logo-delete" onClick={deleteLogo}>
            {content.control_tower_delete}
          </span>
        </div>
      )}

      <input
        ref={ref => setFileInput(ref)}
        type="file"
        style={{ display: "none" }}
        name="video"
        onChange={e => {
          if (e.target && e.target.files && e.target.files[0]) {
            const filesize = (e.target.files[0].size / 1024 / 1024).toFixed(4);

            if (parseInt(filesize, 10) <= 10) {
              onChange(e.target.files[0]);
            } else {
              // eslint-disable-next-line no-alert
              alert(content.control_tower_too_big_file);
            }
          }
        }}
        accept="video/mp4"
      />
    </div>
  );
};

export default VideoUpload;
